import React, { Component } from 'react';
import { db, firebase } from '../firebase';
import styled from "styled-components";
import colors from '../styles/colors';
// import { LargeButton, GreenLargeButton } from '../Components/Button';
// import body from "../images/body2.svg";
import play from "../images/play.svg";
import next from "../images/next.svg";
import previous from "../images/previous.svg";
import stop from "../images/stopplaying.svg";
// // import pause from "../images/pause.svg";
// import complete from "../images/complete.svg";

//TODO: Program, session, exercise status, e.g. Program 2, Session 3, Exercise 4/12
//TODO: Track time from page load: time reading, watching video, exercising, pausing, to completion, specifying results

const AppContainer = styled.div`
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  background-color: #00a0ed;
  border: 1px solid #333;
  display: grid;
  grid-template-rows: 50px 1fr 100px;
  grid-template-areas:
    "Header"
    "Body"
    "MainAction";
`;

const Header = styled.div`
  /* grid-area: Header; */
  background-color: ${colors.first};
  background-color: black;
  color: ${colors.bright};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: normal;
  font-family: "Arial Rounded MT Bold", "Arial Rounded", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Helvetica Neue", "sans-serif";
  box-shadow: 0px 2px 4px ${colors.dark};
`;

const Media = styled.div`
  grid-area: Media;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: ${props => props.hide ? 0 : "56.25%"};
  transition: padding-bottom 300ms ease-in-out;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Body = styled.div`
  /* padding: 20px; */
  color: white;
  font-family: "Arial";
`;

const Icon = styled.img`
  height: 64px; 
  width: 64px;
  left: 0; 
  top: 0;
`;

const SmallIcon = styled.img`
  height: 48px; 
  width: 48px;
  left: 0; 
  top: 0;
  margin: 5px 5px;
`;

const Title = styled.h1`
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  margin: 20px 20px 0;
`;

const SubTitle = styled.h2`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin: 0 20px 20px;
`;

// const SecondaryAction = styled.div`
//   /* grid-area: SecondaryAction; */
//   /* background-color: red; */
// `;

// const Diagnose = styled.div`
//   /* background-color: green; */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative; 
//   height: 0; 
//   width: 100%; 
//   padding: 0;
//   padding-bottom: 100%; 
// `;

// const BodyImage = styled.img`
//   position: absolute; 
//   height: 100%; 
//   width: 100%; 
//   left: 0; 
//   top: 0;
// `;

const MainAction = styled.div`
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const Tips = styled.div`
//   /* grid-area: Tips; */
//   /* background-color: white; */
// `;

class RunPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticating: false,
      running: false,
      authUser: null,
      user: null,
      userId: null,
      currentProgramNumber: 1,
      currentExerciseNumber: 1,
      exercises: null
    };
  }

  onStart = (event) => {
    this.setState({ running: true });
    event.preventDefault();
  }

  onPause = (event) => {
    this.setState({ running: false });
    event.preventDefault();
  }

  onStop = (event) => {
    this.setState({ running: false });
    event.preventDefault();
  }

  onComplete = (event) => {
    this.setState({ running: false });
    event.preventDefault();
  }

  onNext = (event) => {
    this.setState({ running: false });
    if (this.state.currentExerciseNumber < this.state.exercises.length) {
      this.setState({ currentExerciseNumber: this.state.currentExerciseNumber + 1});
    }
    event.preventDefault();
  }

  onPrevious = (event) => {
    this.setState({ running: false });
    if (this.state.currentExerciseNumber > 1) {
      this.setState({ currentExerciseNumber: this.state.currentExerciseNumber - 1});
    }
    event.preventDefault();
  }

  componentDidMount = () => {
    const self = this;

    self.setState({
      authenticating: true
    });

    firebase.auth.onAuthStateChanged(user => {
      if (!user) {
        return this.redirectToRoot("Not authenticated!");
      }

      self.setState({
        authUser: user,
        authenticating: false
      });

      db.getUser(user.email)
        .then(user => {
          self.setState({
            user,
            userId: user.id,
            currentProgramNumber: user.currentProgram
          });
          return user;
        })
        .catch(() => db.addSelf())
        .then(user => { return db.setMissingUserProperties(user); })
        .then(user => { return db.getExercises(user.id, user.currentProgram); })
        .then(exercises => self.setState({exercises}))
        .catch(errorMessage => { this.redirectToRoot(errorMessage); });
    });
  }

  redirectToRoot(errorMessage) {
    console.log(errorMessage);
    window.location.href = '/';
  }

  render() {
    const currentExercise = this.state.exercises === null ? null : this.state.exercises[this.state.currentExerciseNumber - 1];
    return (
      currentExercise === null ?
        <AppContainer>
          <Header>OptimumOnline</Header>
          <Body>
            <Title>Hämtar ditt program...</Title>
          </Body>
          <MainAction>
          </MainAction>
      </AppContainer>
      :
        this.state.running ?
          <AppContainer>
            <Header>OptimumOnline</Header>
            <Body>
              <Media hide>
                <iframe title="Övningsvideo" src={`//www.youtube.com/embed/${currentExercise.videoId}?rel=0&showinfo=0&playsinline=1&modestbranding=1&fs=0&controls=0`}></iframe>
              </Media>              
              <Title>{currentExercise.name}</Title>
              <SubTitle>{currentExercise.repeat} {currentExercise.repeatUnit}</SubTitle>
              <span dangerouslySetInnerHTML={{ __html: currentExercise.text }} />
            </Body>
            <MainAction>
              <SmallIcon src={previous} alt="Föregående övning" onClick={this.onPrevious} />
              <Icon src={stop} alt="Avbryt" onClick={this.onStop} />
              <SmallIcon src={next} alt="Nästa övning" onClick={this.onNext} />
            </MainAction>
          </AppContainer>
          :
          <AppContainer>
          <Header>OptimumOnline</Header>
          <Body>
            <Media>
              <iframe title="Övningsvideo" src={`//www.youtube.com/embed/${currentExercise.videoId}?rel=0&showinfo=0&playsinline=1&modestbranding=1&fs=0&controls=0`}></iframe>
            </Media>
            <Title>{currentExercise.name}</Title>
            <SubTitle>{currentExercise.repeat} {currentExercise.repeatUnit}</SubTitle>
            <span dangerouslySetInnerHTML={{ __html: currentExercise.shortText }} />
          </Body>
          <MainAction>
            <SmallIcon src={previous} alt="Föregående övning" onClick={this.onPrevious} />
            <Icon src={play} alt="Starta övning" onClick={this.onStart} />
            <SmallIcon src={next} alt="Nästa övning" onClick={this.onNext} />
          </MainAction>
        </AppContainer>
    );
  }
}

// export const pageQuery = graphql`
//   query LandingQuery {
//       imageSharp(id: { regex: "/body/" }) {
//         sizes(maxWidth: 1000) {
//           ...GatsbyImageSharpSizes
//         }
//       }
//     }
// `;

export default RunPage;



// query LandingQuery {
//   imageSharp(id: { regex: "/optimum/" }) {
//     sizes(maxWidth: 1000) {
//       ...GatsbyImageSharpSizes
//     }
//   }
// }
